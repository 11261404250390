import React, {useState, useEffect} from "react";
import {
  LoginPageWrapper,
  LoginPageMain,
  LogoAndRegisterSection,
  LoginFormSection,
} from "~/components";
import { PageTitle } from "~/components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { setScheduleTimeState, selectScheduleTimeState } from "~/store/settingsSlice";

const LoginPage = () => {
  return (
    <>
      <PageTitle>로그인</PageTitle>
      <LoginPageWrapper>
        <LoginPageMain>
          <LogoAndRegisterSection />
          <LoginFormSection />
        </LoginPageMain>
      </LoginPageWrapper>
    </>
  );
};

export default LoginPage;
